export const modelLabel = {
    models: [
        {label:'Llama Normal', value:'llama-normal'}, 
        {label:'Llama Finetune', value:'llama-finetune'}, 
        {label:'GPT4O MINI', value:'gpt-4o-mini'}, 
    ]
 }; 

 export const languageList = [
    {label:'English', value:'en'},
    {label:'Hindi', value:'hi'},
    {label:'Kannada', value:'kn'}
];